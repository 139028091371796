import { get, post, postData } from "./axios";

//#region 主页

//#region
// 查询系统广告
export function getSystemAdvertisement(params) {
  return post("/system-advertisement/getSystemAdvertisement", params);
}
// 查询品牌集合
export function selectBrandCollection(params) {
  return post("/car/selectBrandCollection", params);
}
// 查询商品的第二种分类
export function selectCarShopTwoType(params) {
  return post("/shop-two-type/selectCarShopTwoType", params);
}
// 官网查询商品首页
export function selectShopByWhere(params) {
  return post("/car-shop-quality-life/selectShopByWhere", params);
}
// 新官网查询商品首页
export function selectShopPageByShopNewType(params) {
  return post("/car-shop-quality-life/selectShopPageByShopNewType", params);
}


// 官网查询商品详情
export function selectShopDetails(params) {
  return get("/car-shop-quality-life/selectShopDetails", params);
}
// selectUserAddress 查询地址
export function selectUserAddress(params) {
  return post("/user-address/selectUserAddress", params);
}
// 查询sku
export function selectShopSkuTypeAndSku(params) {
  return post("/car-shop-quality-life-sku/selectShopSkuTypeAndSku", params);
}
// 商品添加购物车
export function addShoppingCart(params) {
  return post("/car-shop-shopping-cart/addShoppingCart", params);
}
//#endregion

//#endregion

//#region 汽车

//#region 汽车商城
// 查询车辆形状分类
export function selectCarShapeType(params) {
  return post("/car-hot/selectCarShapeType", params);
}
// 根据品牌查询车型集合
export function selectCarTypeByBrandCodeNumber(params) {
  return get("/car/selectCarTypeByBrandCodeNumber", params);
}
// 根据车型查询版本集合
export function selectVersionListByCarType(params) {
  return get("/car/selectVersionListByCarType", params);
}
// 官网后台多条件查询车辆
export function selectVersionListByWhere(params) {
  return post("/car/selectVersionListByWhere", params);
}
// 查询官网车辆推荐
export function selectCarHotOfficial(params) {
  return post("/car-hot-official/selectCarHotOfficial", params);
}
//#endregion

//#region 汽车品牌
// 根据品牌查询品牌官网介绍
export function selectBrandIntroduce(params) {
  return get("/car/selectBrandIntroduce", params);
}
// 根据车型查询最高版本模型和车辆介绍
export function selectVersionMaxAndModel(params) {
  return get("/car/selectVersionMaxAndModel", params);
}
// 根据车辆版本codeNumber获取车辆详情信息、或者根据车型codeNumber查询配置最高版本详情
export function selectCarByVersionCode(params) {
  return post("/car/selectCarByVersionCode", params);
}
// 查询官网车车型详情
export function selectCarIntroduction(params) {
  return get("/car-official-introduce/selectCarIntroduction", params);
}
// 官网查询车辆类型的配置单
export function selectCarTypeConfigurationPicture(params) {
  return get("/car/selectCarTypeConfigurationPicture", params);
}
// 查询车型的轮播图
export function selectCarTypeConfigurationPictures(params) {
  return get("/car/selectCarTypeConfigurationPictures", params);
}
//#endregion

//#region 汽车选配
// 根据车辆版本->查询电池和驱动
export function selectCarMainConfig(params) {
  return get("/car/selectCarMainConfig", params);
}
//查询选配目录状态
export function selectOptionalCatalogStatus(params) {
  return post("/car/selectOptionalCatalogStatus", params);
}
// 查询车辆外观 轮胎 轮毂
export function selectCarAppearance(params) {
  return get("/car/selectCarAppearance", params);
}
// 查询车辆内饰
export function selectCarTrim(params) {
  return get("/car/selectCarTrim", params);
}
// 查询车辆选装
export function selectCarOptional(params) {
  return get("/car/selectCarOptional", params);
}
// 查询车辆配件
export function selectCarAttachment(params) {
  return get("/car/selectCarAttachment", params);
}
// 查询车辆软件
export function selectCarSoftware(params) {
  return get("/car/selectCarSoftware", params);
}
// 查询车辆个性化
export function selectCarIndividuation(params) {
  return get("/car/selectCarIndividuation", params);
}
// 查询车辆配置关系排除
export function selectCarDisExclude(params) {
  return get("/car-dis-exclude/selectCarDisExclude", params);
}
// 创建车辆订单
export function foundCarOrder(params) {
  return post("/car-order/foundCarOrder", params);
}
// 查询公司的银行卡
export function selectMeilinBankCard(params) {
  return post("/meilin-bank-card/selectMeilinBankCard", params);
}
// 车辆订单支付凭证上传表
export function uploadPayment(params) {
  return post("/car-order-pay/uploadPayment", params);
}
// 上传文件提速版
export function uploadFile(params) {
  return post("/file-info/pub/uploadFile", params);
}
// 删除文件
export function deleteFile(params) {
  return get("/file-info/pub/deleteFile", params);
}
//#endregion

//#endregion

//#region 文件上传
// 校验文件是否存在
export function check(params) {
  return get("/upload/multipart/check", params);
}
// 分片初始化
export function init(params) {
  return post("/upload/multipart/init", params);
}
// 完成上传
export function merge(params) {
  return post("/upload/multipart/merge", params);
}
// 分片上传
export function uploadfileAWSS3(params) {
  return post("/upload/uploadfileAWSS3", params);
}
//#endregion

//#region App下载

//#region
// 查询官方和渠道
export function selectOfficialAndChannel(params) {
  return post("/official-and-channel/selectOfficialAndChannel", params);
}
//#endregion

//#endregion

//#region 查询系统参数对象
// 查询系统参数对象
export function selectSystemParam(params) {
  return get("/system-param/selectSystemParam", params);
}
//#endregion

//#region 足迹
// 足迹
export function userAddFootprint(params) {
  return post("/user-footprint/userAddFootprint", params);
}
//#endregion

export const getApi = (url, params) => {
  return get(url, params);
};
export const postApi = (url, data) => {
  return post(url, data);
};
