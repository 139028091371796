/*
 * @Descripttion: 描述
 * @Author: 刘黎
 * @Date: 2024-01-29 13:06:48
 * @LastEditors: 刘黎
 * @LastEditTime: 2024-03-27 16:25:48
 */
import { routers } from './routers.js'
import { createRouter,createWebHashHistory,createWebHistory  } from "vue-router";

const router = createRouter({
    history: createWebHashHistory(),
    routes: routers,
    // 每次切换路由后滚动条置顶
    scrollBehavior(to, from, savedPosition) {
        return { left: 0, top: 0 }
    }
})

export default router