/*
 * @Descripttion: 描述
 * @Author: 刘黎
 * @Date: 2024-01-30 20:16:29
 * @LastEditors: 刘黎
 * @LastEditTime: 2024-04-23 14:56:04
 */

const communityRouters = [
  // 登录注册
  {
    title: '选择登录方式',
    path: '/login/loginIndex',
    name: 'loginIndex',
    component: () => import("../views/login/loginIndex.vue")
  },
  {
    title: '密码登录',
    path: '/login/passwordLogin',
    name: 'passwordLogin',
    component: () => import("../views/login/passwordLogin.vue")
  },
  {
    title: '注册',
    path: '/login/register',
    name: 'register',
    component: () => import("../views/login/register.vue")
  },
  {
    title: '验证码登录',
    path: '/login/codeLogin',
    name: 'codeLogin',
    component: () => import("../views/login/codeLogin.vue")
  },
  {
    title: '修改密码',
    path: '/login/editPassword',
    name: 'editPassword',
    component: () => import("../views/login/editPassword.vue")
  },
  // 社区
  {
    title: '推荐页面',
    path: '/community/index',
    name: 'communityIndex',
    component: () => import("@/views/community/index.vue")
  },
  {
    title: '文章详情页面',
    path: '/community/communityDetail',
    name: 'communityDetail',
    component: () => import("@/views/community/communityDetail.vue")
  },
  {
    title: '我的主页',
    path: '/community/myIndex',
    name: 'communityMyIndex',
    component: () => import("@/views/community/myIndex.vue")
  },
  {
    title: '编辑资料',
    path: '/community/editInfo',
    name: 'communityEditInfo',
    component: () => import("@/views/community/editInfo.vue")
  },
  {
    title: '我的关注',
    path: '/community/myFollow',
    name: 'communityMyFollow',
    component: () => import("@/views/community/myFollow.vue")
  },
]
export default communityRouters
