<!--
 * @Descripttion: 描述
 * @Author: 刘黎
 * @Date: 2024-03-09 17:02:26
 * @LastEditors: 刘黎
 * @LastEditTime: 2024-03-15 20:15:17
-->
<template>
  <div class="noData">
    <img src="@/assets/shoppingF/noData.png" />
    <p class="noDataText"> {{ $t("trolleyNoData")}}</p>
  </div>
</template>

<script setup>
import { getCurrentInstance, onMounted } from "vue";
const $t = getCurrentInstance().appContext.config.globalProperties.$t;//引入语言

onMounted(async () => {
})
</script>

<style lang="scss" scoped>
.noData{
  width: 100%;
  padding: 40px 0;
  text-align: center;
  background: #fff;
  img{
    width: 361px;
    height: 300px;
  }
  p{
    margin-top: 40px;
    font-weight: bold;
    font-size: 16px;
    color: #B4B6BA;
  }
}
</style>
