<template>
  <div class="headers">
    <!-- logo -->
    <div class="logoImg" @click="logoImgClick">
      <div class="logoImgLeft">
        <img src="../../assets/index/log.png" class="logClass">
      </div>
      <div class="logoImgRight">
        {{ $t("meilinCar") }}
      </div>
    </div>
    <!-- 导航菜单 -->
    <div class="menus">
      <div
        v-for="(item,index) in menusList"
        :key="index"
        :class="index == activeIndex ? 'menusListItem active' : 'menusListItem' "
        @click="menusListClick(index)">
        <div class="textClas">
          <div class="text">{{ item.title }}</div>
        </div>
        <div class="linkClass">
          <div class="link"></div>
        </div>
      </div>
    </div>
    <!-- 登录or切换语言 -->
    <div class="logon">
      <div class="logonRight">
        <el-dropdown @command="languageChange">
          <span class="el-dropdown-link">
            <template v-if="languageValue===1">ZH</template>
            <template v-if="languageValue===2">EN</template>
            <template v-if="languageValue===3">russia</template>
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-for="item in languageList" :key="item.value" :command="item.value">{{ item.label }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <!-- <el-select v-model="languageValue" size="large" style="width: 120px;" @change="languageChange">
          <el-option
            v-for="item in languageList"
            :key="item.value"
            :label="item.label"
            :value="item.value"/>
        </el-select> -->
      </div>
      <div class="logonLeft">
        <!-- <el-button v-if="!isToken" class="logonBtn" @click="login()">{{ $t("logOn") }}</el-button> -->
        <a v-if="!isToken" href="javascript:;" class="logonBut" @click="login()">{{ $t("logOn") }}<img src="@/assets/index/userICo.svg"></a>
        <div v-else class="loginInfo">
          <!-- <el-dropdown @command="loginExit">
            <span class="el-dropdown-link">
              {{ infoData.nickname }}
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item :command="1">{{ $t("exit") }}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown> -->
          <div class="name">{{ infoData.nickname }}</div>
          <div class="exit" @click="loginExit()">{{ $t("exit") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import i18n from "@/locales/i18n.js"
import { ref, reactive, getCurrentInstance, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
const $t = getCurrentInstance().appContext.config.globalProperties.$t;//引入语言
const router = useRouter();


onMounted(() =>{//实例创建之后更新选择的菜单模块
  activeIndex.value = sessionStorage.getItem('activeIndex')
  if(!sessionStorage.getItem('languageValue')){
    sessionStorage.setItem('languageValue',1)
  } else {
    languageValue.value = Number(sessionStorage.getItem('languageValue'))
    if(languageValue.value == 1){//中文
      i18n.global.locale.value = 'zh'
    } else if(languageValue.value == 2){//英文
      i18n.global.locale.value = 'en'
    } else if(languageValue.value == 3){//俄文
      i18n.global.locale.value = 'russia'
    }
  }
  loginInfo();
})

const logoImgClick = () =>{
  sessionStorage.setItem('activeIndex', null);
  activeIndex.value = null;
  router.push("/");
}

//#region 导航栏
let menusList = computed(() => {
  let list = reactive([
    {
      title : $t("car")
    },
    {
      title : $t("shoppingMall")
    },
    {
      title : $t("community")
    },
    {
      title : $t("store")
    },
    {
      title : $t("aboutUs")
    },
    {
      title : $t("dealerWebsite")
    },
    {
      title : $t("newsCenter")
    },
  ])
  return list
})
let activeIndex = ref(null);//导航栏选中的值
const menusListClick = (index) => {//切换导航栏模块
  sessionStorage.setItem('activeIndex', index);
  activeIndex.value = index;
  switch(index){
    case 0 : //跳转汽车商城
      router.push("/car/autoMall");
      break;
    case 1 : //跳转商品商城
      router.push("/shoppingMall/commodityMall");
      break;
    case 2 : //跳转社区
      router.push("/community/index");
      break;
    case 3: // 门店位置
      router.push("/store/storeLocator");
      break;
    case 4: //关于我们
      router.push("/system/aboutUs");
      break;
    case 5: // 经销商网站
      router.push("/store/dealer");
      break;
    case 6: // 新闻中心
      router.push("/store/news");
      break;
    default:
  }
}
//#endregion

//#region 语言
const languageList = computed(() => {
  let list = reactive([
    {
      label: $t('chinese'),
      value: 1,
      language: "zh"
    },
    {
      label: $t('english'),
      value: 2,
      language: "en"
    },
    {
      label: $t('russia'),
      value: 3,
      language: "russia"
    }
  ])
  return list
})
let languageValue = ref(1);//默认语言为中文
const languageChange = (index) => {//切换语言
  console.log(index)
  sessionStorage.setItem('languageValue', index)
  if(index == 1){//中文
    i18n.global.locale.value = 'zh'
  } else if(index == 2){//英文
    i18n.global.locale.value = 'en'
  } else if(index == 3){//俄文
    i18n.global.locale.value = 'russia'
  }
  languageValue.value = index;
  //const state = reactive({});
  //state._rawValue[0]++; // Vue3版本
  window.location.reload();
}

const login = ()=>{
  activeIndex.value = -1;
  router.push({ path: '/login/loginIndex' });
}
//#endregion

const isToken = ref(false)
const infoData = ref(false)
// 是否登录验证，获取用户显示
function loginInfo() {
  isToken.value = localStorage.token ? true : false
  if(isToken.value){
    //console.log(localStorage.selectUserData)
    infoData.value = JSON.parse(localStorage.selectUserData)
  }
}
// 退出登录 暂时没有接口直接前端退出清除所有缓存
function loginExit() {
  localStorage.clear()
  isToken.value = false
  infoData.value = ''
}
</script>


<style lang="scss" scoped>
.headers{
  background-color: #fff;
  width: 100%;
  height: 100px;
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(#000000, 0.08) ;
  .logoImg{
    display: flex;
    align-items: center;
    .logoImgLeft{
      cursor: pointer;
      .logClass{
        display: block;
        width: 230px;
        height: 49px;
      }
    }
    .logoImgRight{
      margin-left: -9px;
      cursor: pointer;
      font-size: 19px;
      font-weight: 400;
      color: #333333;
      white-space: nowrap;
      margin-bottom: 3px;
    }
  }

  .menus{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    margin: 0 5%;
    font-size: 18px;
    font-weight: 500;
    .menusListItem{
      cursor: pointer;
      color: #4A4A4A;
      padding: 0 9px;
      .textClas{
        margin-top: 3px;
        .text{
          font-weight: 500;
          font-size: 18px;
          word-break: break-word;
          white-space: nowrap;
        }
      }
      
      .linkClass{
        display: flex;
        justify-content: center;
        .link{
          width: 15px;
          height: 2px;
          background-color: transparent;
          border-radius: 5px;
          margin-top: 3px;
        }
      }
    }
    .menusListItem:hover{
      color: #E0C6A4;
      cursor: pointer;
      // cursor: grabbing;
      // cursor: -moz-grabbing;
      // cursor: -webkit-grabbing;
      .text{
        font-weight: 500;
        font-size: 18px;
        word-break: break-word;
      }
      .linkClass{
        display: flex;
        justify-content: center;
        .link{
          width: 15px;
          height: 2px;
          background-color: #E0C6A4;
          border-radius: 5px;
          margin-top: 3px;
        }
      }
    }
    .active{
      color: #E0C6A4;
      .linkClass{
        display: flex;
        justify-content: center;
        .link{
          width: 15px;
          height: 2px;
          background-color: #E0C6A4;
          border-radius: 5px;
          margin-top: 3px;
        }
      }
    }
  }
  .logon{
    display: flex;
    align-items: center;
    .logonLeft{
      .logonBut{
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 18px;
        color: #4A4A4A;
        line-height: 23px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        text-decoration: none;
        img{
          width: 26px;
          height: 26px;
          margin-left: 4px;
        }
      }
      .loginInfo{
        line-height: 40px;
        display: flex;
        font-size: 12px;
        .exit{
          cursor: pointer;
          margin-left: 10px;
          color: #E0C6A4;
        }
      }
    }
    .logonRight{
      cursor: pointer;
      margin-right: 20px;
      font-size: 18px;
      color: #4A4A4A;
      ::v-deep .el-icon{
        top: 5px;
      }
      .el-dropdown{
        font-size: 18px;
      }
    }
  }
}
@media (max-width:1670px) {// 小于1600高度
  .headers{
    padding: 0 20px 0 80px;
    .menus{
      margin: 0 10px;
      .menusListItem .textClas .text{
        font-size: 16px;
      }
    }
    .logonRight{
      font-size: 16px!important;
    }
    .el-dropdown{
      font-size: 16px!important;
    }
    .logonBut{
      font-size: 16px!important;
    }
  }
}
</style>
