/*
 * @Descripttion: 描述
 * @Author: 刘黎
 * @Date: 2024-01-29 13:06:48
 * @LastEditors: 刘黎
 * @LastEditTime: 2024-02-03 15:07:41
 */
import Vue, { createApp } from 'vue'
import App from './App.vue'
// import { TUIComponents, TUIChatKit, genTestUserSig } from "./TUIKit"; 找不到路径原因不明
import "@/permission" // 权限
import '@/style/base.scss' // 引入全局公用样式

// 国际化
import i18n from "./locales/i18n.js"
const { t } = i18n.global;

// 路由
import router from './router/index.js'

// element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
const app = createApp(App)

app.config.globalProperties.$t = t; // i18l方法 注册为全局方法

import * as ELIcons from '@element-plus/icons-vue'
for (let iconName in ELIcons) {
  app.component(iconName, ELIcons[iconName])
}

import * as globalComponents from './components/registrationComponent.js';
Object.keys(globalComponents).forEach(key => {
  const component = globalComponents[key];
  app.component(key, component);
});
import myUI from './components'

// 引入IM
import TIM from '@tencentcloud/chat';
import TIMUploadPlugin from 'tim-upload-plugin';
let options = {
  SDKAppID: 1600001774//1600001774 1600009253
};
let chat = TIM.create(options); 
chat.setLogLevel(4); 
chat.registerPlugin({'tim-upload-plugin': TIMUploadPlugin});
app.config.globalProperties.$chat = chat; // 挂载
app.config.globalProperties.$TIM = TIM; // 挂载

app.use(router); //注册路由
app.use(i18n); //注册国际化
app.use(ElementPlus) //注册element-plus
app.use(myUI)
app.mount('#app');
