<!--
 * @Descripttion: 描述
 * @Author: 刘黎
 * @Date: 2024-03-23 13:33:09
 * @LastEditors: 刘黎
 * @LastEditTime: 2024-03-23 17:56:25
-->
<template>
  <router-view></router-view>
</template>


<script>
export default {
  name: 'App',
}
</script>


<style>
*{
  padding: 0;
  margin: 0;
  font-family: 'Arial', 'Helvetica Neue', 'PingFangBold', 'Aeroport';
}
@font-face{
	font-family: "PingFangBold";
	src: url("@/assets/ttf/PingFang Bold.ttf");
}

/* ::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.15);
  transition: color 0.2s ease;
} */
</style>
